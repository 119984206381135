import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppOptions } from '@root/app/shared/apps.types';

import { ConfigState } from './config.types';

const configInitialState: ConfigState = {
	apps: {},
	appOptions: {
		appStatuses: {},
		appTypes: {},
		vpnOptions: {},
	},
	appsList: [],
};

export const ConfigStore = signalStore(
	{ providedIn: 'root' },
	withState(configInitialState),
	withMethods((store) => ({
		addApps(apps: AndroidAppConfig[]) {
			patchState(store, (state) => ({
				...state,
				apps: { ...state.apps, ...apps.reduce((acc, app) => ({ ...acc, [app.appId]: app }), {}) },
				appsList: [...new Set([...state.appsList, ...apps.map((app) => app.appId)])],
			}));
		},
		addAppOptions(appOptions: AppOptions) {
			patchState(store, (state) => ({
				...state,
				appOptions: {
					...state.appOptions,
					...appOptions,
				},
			}));
		},
	})),
);
