import { inject, Injectable } from '@angular/core';
import { ApiService } from '@root/app/core/api/api.service';
import { tap } from 'rxjs';

import { App } from './app';
import { AppsStore } from './apps.store';

@Injectable({
	providedIn: 'root',
})
export class AppsService {
	#appsStore = inject(AppsStore);
	httpClient = inject(ApiService);

	getApps() {
		return this.httpClient.get<App[]>('/android/apps').pipe(
			tap((data) => {
				if (Array.isArray(data)) {
					this.#appsStore.addApps(data);
				}
			}),
		);
	}

	updateApp(app: App) {
		return this.httpClient.put<App>(`/android/apps/${app.id}`, app);
	}

	deleteApp(appId: string) {
		return this.httpClient.delete<App>(`/android/apps/${appId}?force=true`);
	}
}
