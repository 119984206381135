import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { App } from './app';

const appsInitialState = {
	apps: <Record<string, App>>{},
};

export const AppsStore = signalStore(
	{ providedIn: 'root' },
	withState(appsInitialState),
	withComputed((store) => ({
		appsList: computed(() => Object.values(store.apps())),
	})),
	withMethods((store) => ({
		addApps(appList: App[]) {
			patchState(store, (state) => ({
				...state,
				apps: {
					...state.apps,
					...appList.reduce((relation, app) => ({ ...relation, [app.id]: app }), {}),
				},
			}));
		},
		getApp(appId: string) {
			return store.apps()[appId];
		},
		removeApp(appId: string) {
			patchState(store, (state) => {
				const apps = { ...state.apps };
				delete apps[appId];
				return { ...state, apps };
			});
		},
		updateApp(appId: string, app: Partial<App>) {
			patchState(store, (state) => {
				const newAppData = { ...(state.apps[appId] || {}), ...app };

				return { ...state, apps: { ...state.apps, [appId]: newAppData } };
			});
		},
	})),
);
