export const VPN_CONFIG_LABELS: Record<string, string> = {
	bypassed: 'Bypass',
	filtered: 'Filtered',
};

export const ACTIVE_OPTIONS = [
	{
		label: 'Active',
		value: 2,
	},
	{
		label: 'Disable',
		value: 5,
	},
	{
		label: 'Hidden',
		value: 3,
	},
	{
		label: 'Block Uninstall',
		value: 4,
	},
];
